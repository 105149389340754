// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-donate-success-index-js": () => import("./../../../src/pages/donate/success/index.js" /* webpackChunkName: "component---src-pages-donate-success-index-js" */),
  "component---src-pages-give-success-index-js": () => import("./../../../src/pages/give/success/index.js" /* webpackChunkName: "component---src-pages-give-success-index-js" */),
  "component---src-pages-make-success-index-js": () => import("./../../../src/pages/make/success/index.js" /* webpackChunkName: "component---src-pages-make-success-index-js" */),
  "component---src-pages-map-success-index-js": () => import("./../../../src/pages/map/success/index.js" /* webpackChunkName: "component---src-pages-map-success-index-js" */),
  "component---src-pages-request-success-index-js": () => import("./../../../src/pages/request/success/index.js" /* webpackChunkName: "component---src-pages-request-success-index-js" */),
  "component---src-pages-success-index-js": () => import("./../../../src/pages/success/index.js" /* webpackChunkName: "component---src-pages-success-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-cause-page-js": () => import("./../../../src/templates/cause-page.js" /* webpackChunkName: "component---src-templates-cause-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-corestory-page-js": () => import("./../../../src/templates/corestory-page.js" /* webpackChunkName: "component---src-templates-corestory-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-info-page-js": () => import("./../../../src/templates/info-page.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-jobs-page-js": () => import("./../../../src/templates/jobs-page.js" /* webpackChunkName: "component---src-templates-jobs-page-js" */),
  "component---src-templates-map-page-js": () => import("./../../../src/templates/map-page.js" /* webpackChunkName: "component---src-templates-map-page-js" */),
  "component---src-templates-ppe-page-js": () => import("./../../../src/templates/ppe-page.js" /* webpackChunkName: "component---src-templates-ppe-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-stats-page-js": () => import("./../../../src/templates/stats-page.js" /* webpackChunkName: "component---src-templates-stats-page-js" */),
  "component---src-templates-stories-home-page-js": () => import("./../../../src/templates/stories-home-page.js" /* webpackChunkName: "component---src-templates-stories-home-page-js" */),
  "component---src-templates-stripe-checkout-js": () => import("./../../../src/templates/stripe-checkout.js" /* webpackChunkName: "component---src-templates-stripe-checkout-js" */),
  "component---src-templates-supplies-page-js": () => import("./../../../src/templates/supplies-page.js" /* webpackChunkName: "component---src-templates-supplies-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-tournament-page-js": () => import("./../../../src/templates/tournament-page.js" /* webpackChunkName: "component---src-templates-tournament-page-js" */)
}

